import axios from "axios";
import expressAsyncHandler from "express-async-handler";

//getState.Name of reducer u can get state
export const createOrder=(order)=>async(dispatch,getState)=>{
    try{
    dispatch({
        type:"CREATE_ORDER_REQUEST",
    })

    const {data}= await axios.post(`/api/order/createOrder`,order)
    dispatch({
        type:"CREATE_ORDER_SUCCESS",
        payload:data
    })
}
catch(error){
    dispatch({
        type:"CREATE_ORDER_FAILURE",
        payload:error
    })
}
}
export const getOrderDetails=(orderId)=>expressAsyncHandler( async(dispatch,getState)=>{
    try{
    dispatch({
        type:"ORDER_DETAILS_REQUEST",
    })

    const {data}= await axios.get(`/api/order/getOrderDetails/${orderId}`)
    dispatch({
        type:"ORDER_DETAILS_SUCCESS",
        payload:data
    })
}
catch(error){
    dispatch({
        type:"ORDER_DETAILS_FAILURE",
        payload:error
    })
}
})

export const payOrder=(orderId,paymentResult)=>expressAsyncHandler( async(dispatch,getState)=>{
    try{
    dispatch({
        type:"ORDER_PAY_REQUEST",
    })

    const {data}= await axios.put(`/api/order/getOrderDetails/${orderId}/pay`,paymentResult)
    dispatch({
        type:"ORDER_PAY_SUCCESS",
        payload:data
    })
}
catch(error){
    dispatch({
        type:"ORDER_PAY_FAILURE",
        payload:error
    })
}

})


export const getUserOrderList=()=>expressAsyncHandler( async(dispatch,getState)=>{
    try{
    dispatch({
        type:"ORDER_LIST_USER_REQUEST",
    })

    const {data}= await axios.get(`/api/order/getMyOrders`)
    dispatch({
        type:"ORDER_LIST_USER_SUCCESS",
        payload:data
    })
}
catch(error){
    dispatch({
        type:"ORDER_LIST_USER_FAILURE",
        payload:error
    })
}
})