import React,{useEffect,useState} from 'react'
import{Link,useSearchParams,useNavigate} from 'react-router-dom'
import {Form,Button,Row,Col,Table} from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { register, registerComplete,getUser} from '../Actions/userAction'
import {LinkContainer} from 'react-router-bootstrap'
import Message from '../Components/Message'
import Loader from './loader'
import { getUserOrderList } from '../Actions/orderActions'
const   ProfileScreen = () => {
    let [name,setName]= useState('')
    let [email,setEmail]= useState('')
    let [password,setPassword]= useState('')
    let [confirmPassword,setConfirmPassword]= useState('')
    let[message,setMessage]=useState(null)
    const [searchParams,setSearchParams]=useSearchParams()
    const redirect=searchParams.get('search')? searchParams.get('search').split('=')[1]:'/'
    const userRegister=useSelector(state=>state.userLogin)
    const {loading,userInfoError,userProf,userInfo}=userRegister
    const dispatch=useDispatch()
    const navigate = useNavigate();
    let passwordmatch=false;
    console.log(userProf&&userProf.email)
    useEffect(()=>{
        
          dispatch(getUser())
          
            
    
             
        }
    ,[redirect,passwordmatch])
    const submitHandler=(e)=>{
       
        //prevent default will prevent refresh
e.preventDefault()
if(password===confirmPassword){
  setMessage(null)
dispatch(register(name,email,password))}
else{
  setMessage("true")
}


    }
  return (
    
    <div>
      
      {loading? <div>{userInfoError && <Message text={"Please Login In to View Your Profile"} variant='danger'/>}<Loader/></div> : 
    <Row className=''>
        <Col xs={12} md={6}> 
    <h1>My Profile</h1>

    {message && <Message text={"Oops Passwords Does Not Match"} variant='danger'/ >}
    
    <Form onSubmit={submitHandler}>

    <Form.Group className='mt-4'>
<Form.Label>Name</Form.Label>
<Form.Control
type='text'
className="name"
id="name"
placeholder='Enter Your Full Name'
value={userInfo && userInfo.name}
onChange={(e) => setName(e.target.value)}
></Form.Control>
</Form.Group>


    <Form.Group className='mt-4'>
<Form.Label>Email Address</Form.Label>
<Form.Control
type='email'
placeholder='Enter email'
value={userInfo&&  userInfo.email}
className="email"
id='email'
onChange={(e) => setEmail(e.target.value)}
></Form.Control>
</Form.Group>

<Form.Group  className='mt-4'>
<Form.Label>Password </Form.Label>
<Form.Control   
type='password'
placeholder='Enter password'
className="password"
value={password}
required={true}
id='password'
onChange={(e) => setPassword(e.target.value)}
></Form.Control>
</Form.Group>

<Form.Group  className='mt-4'>
<Form.Label>Confirm Your Password </Form.Label>
<Form.Control   
type='password'
className="confirmpassword"
id="confirmpassword"
placeholder='Confirm password'
value={confirmPassword}
onChange={(e) => setConfirmPassword(e.target.value)}
></Form.Control>
</Form.Group>

<Button type='submit' variant='primary' className='mt-4'>
Update Profile

</Button>
    </Form>
    
    </Col>
   
    </Row>
   
}
      
    </div>
  )
}

export default ProfileScreen