import {configureStore} from '@reduxjs/toolkit'
import { cartReducer } from './Reducers/cartReducer';
import { productDetailReducer } from './Reducers/productDetailsReducer';
import { productReducer } from './Reducers/productReducers';
import {userDetailsReducer, userDetailsUpdateReducer, userListReducer, userLoginReducer} from './Reducers/userReducer'
import { createOrderReducer } from './Reducers/orderReducer';

const cartItemsFromStorage=localStorage.getItem('cartItems')? JSON.parse(localStorage.getItem('cartItems')):[]
const shippingAddressFromStorage=localStorage.getItem('shippingAddress')? JSON.parse(localStorage.getItem('shippingAddress')):{}
const userInfoFromStorage=localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')):null
const initialState={
    cart:{cartItems:cartItemsFromStorage,shippingAddress:shippingAddressFromStorage},
    userLogin:{userInfo:userInfoFromStorage}
}
const store =configureStore({
    reducer:{
        productList:productReducer,
        productDetailList:productDetailReducer,
        cart:cartReducer,
        userLogin:userLoginReducer,
        order:createOrderReducer,
        userList:userListReducer,
        userDetails:userDetailsReducer,
        userUpdate:userDetailsUpdateReducer
        
    },
    // set initial state
    preloadedState:initialState
})

export default store;