import React,{useEffect} from 'react'
import{Row,Col} from 'react-bootstrap'
import {useDispatch,useSelector} from 'react-redux'
import Product from '../Components/Product'
import {productAction} from '../productAction'
import Loader from './loader'
const HomeScreen = () => {
  const dispatch=useDispatch()
  // #! Should be same as one in store(state.productList)
  const productList=useSelector(state=>state.productList)
  const{loading,products,error}=productList
  useEffect(()=>{

   dispatch(productAction())

  },[dispatch])
  return (
    <>
<h1>Featured Products</h1>
{loading?<Loader/>:
<Row>
    {products && products.map((product)=>(
        <Col sm={12} md={6} lg={4} xl={3}>
        <Product product={product} />
        </Col>
    ))}
</Row>}

    </>
  )
}

export default HomeScreen