import axios from "axios";


//getState.Name of reducer u can get state
export const addToCart=(id,qty)=>async(dispatch,getState)=>{
    const {data}= await axios.get(`/api/product/getProductById/${id}`)
    dispatch({
        type:"CART_ADD_ITEM",
        payload:{
            product:data._id,
            name:data.name,
            image:data.images,
            price:data.price,
            countInStock:data.countInStock,
            qty
        }
    })
    localStorage.setItem('cartItems',JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart =(id)=>(dispatch,getState)=>{
    dispatch({
        type:"CART_REMOVE_ITEM",
        payload:id
    })
    localStorage.setItem('cartItems',JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress =(data)=>(dispatch,getState)=>{
    dispatch({
        type:"CART_SAVE_SHIPPING_ADDRESS",
        payload:data
    })
    localStorage.setItem('shippingAddress',JSON.stringify(data))
}
export const savePaymentMethod =(data)=>(dispatch,getState)=>{
    dispatch({
        type:"CART_SAVE_PAYMENT_METHOD",
        payload:data
    })
    localStorage.setItem('paymentMethod',JSON.stringify(data))
}