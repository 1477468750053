import {createReducer} from "@reduxjs/toolkit"

const initialState={order:{},orderDetails:{},orderPay:{}}

export const createOrderReducer=createReducer({initialState},{
    CREATE_ORDER_REQUEST: (state,action)=>{
       return { loading:true,
        order:{}
       }
    },
    CREATE_ORDER_SUCCESS: (state,action)=>{
      return{  loading:false,
        order:action.payload
      }
    },
    CREATE_ORDER_FAILURE: (state,action)=>{
        return {loading:false,
        error:action.payload}
    },  
    ORDER_DETAILS_REQUEST: (state,action)=>{
      return { 
        ...state,
        loading:true,
       orderDetails:{}
      }
   },
   ORDER_DETAILS_SUCCESS: (state,action)=>{
     return{  
      ...state,
      loading:false,
      
       orderDetails:action.payload
     }
   },
   ORDER_DETAILS_FAILURE: (state,action)=>{
       return {
        ...state,
        loading:false,
       error:action.payload}
   },
   ORDER_PAY_REQUEST:(state,action)=>{
    return {
      ...state,
    loading1: true,
    success:false
    }
  },
      ORDER_PAY_SUCCESS:(state,action)=>{
    return {
      ...state,
    loading1: false,
    success:true
    }
  },
  ORDER_PAY_FAILURE:(state,action)=>{
    return {
    loading1: false,
    success:false,
    error:action.payload
    }
  },
  ORDER_PAY_RESET:(state,action)=>{
    return { }
  },
  ORDER_LIST_USER_REQUEST:(state,action)=>{
    return{
      loadingOrderUserList:true
    }
  },
  ORDER_LIST_USER_SUCCESS:(state,action)=>{
    return{
      loadingOrderUserList:false,
      OrderUserList:action.payload
    }
  },
  ORDER_LIST_USER_FAILURE:(state,action)=>{
    return{
      loadingOrderUserList:false,
      errorOrderList:action.payload
    }
  },
})

  
 

