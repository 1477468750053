import {createReducer} from "@reduxjs/toolkit"


export const cartReducer=createReducer({cartItems:[],shippingAddress:{}},{
    CART_ADD_ITEM: (state,action)=>{
       const item=action.payload
    const existItem=state.cartItems.find(x=>x.product === item.product)

    if(existItem){
        return {
            ...state,
            cartItems :state.cartItems.map(x=>x.product === existItem.product ? item :x)
        }
    }else {
        return{
            ...state,
            cartItems:[...state.cartItems,item]
        }
    }
    },
    CART_REMOVE_ITEM:(state,action)=>{
        return{
            ...state,
            cartItems:state.cartItems.filter((x)=> x.product !==action.payload)
        }
    },
    CART_RESET:(state,action)=>{
        localStorage.removeItem('cartItems')
        return{
            cartItems:[]
        }
    },
    CART_SAVE_SHIPPING_ADDRESS:(state,action)=>{
        return{
            ...state,
            shippingAddress:action.payload
        }
    },
    CART_SAVE_PAYMENT_METHOD:(state,action)=>{
        return{
            ...state,
            paymentMethod:action.payload
        }
    }

})