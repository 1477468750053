import axios from "axios"


export  const productAction=()=>async(dispatch)=>{
try {
    dispatch({type:"product_request"})
    const {data}=await axios.get(`/api/product/getAllProducts`)
    dispatch({type:"product_success",payload:data})
} catch (error) {
    dispatch({type:"product_failed",payload:error.response && error.response.data.message ?error.response.data.message : error.response}
    )
}
}

export  const productDetailAction=(id)=>async(dispatch)=>{
    try {
        dispatch({type:"product_detail_request"})
        const {data}=await axios.get(`/api/product/getProductById/${id}`)
        dispatch({type:"product_detail_success",payload:data})
    } catch (error) {
        dispatch({type:"product_detail_failed",payload:error.response && error.response.data.message ?error.response.data.message : error.response}
        )
    }
    }