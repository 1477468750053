import React,{useEffect,useState} from 'react'
import{Link,useSearchParams,useNavigate} from 'react-router-dom'
import {Form,Button,Row,Col,Container} from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import {login} from '../Actions/userAction'
import FormContainer from '../Components/FormContainer'
import Message from '../Components/Message'
const   LoginScreen = () => {
    let [email,setEmail]= useState('')
    let [password,setPassword]= useState('')
    const [searchParams,setSearchParams]=useSearchParams()
    const redirect=searchParams.get('search')? searchParams.get('search').split('=')[1]:'/'
    const userLogin=useSelector(state=>state.userLogin)
    const {loading,error,userInfo}=userLogin
    const dispatch=useDispatch()
    const navigate = useNavigate();
    useEffect(()=>{
        if(userInfo){
                navigate(redirect)
        }
    },[redirect,userInfo])
    const submitHandler=(e)=>{
        //dispath login
        //prevent default will prevent refresh
e.preventDefault()
dispatch(login(email,password))
    }
  return (
    <div>
      <Container>
        <Row className='justify-content-md-center'>
            <Col xs={12} md={6}> 
        <h1>Sign In</h1>
        {error && <Message text={error} variant='danger'/>}
        <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'className='mt-4'>
<Form.Label>Email Address</Form.Label>
<Form.Control
type='email'
placeholder='Enter email'
value={email}
onChange={(e) => setEmail(e.target.value)}
></Form.Control>
</Form.Group>

<Form.Group controlId='password' className='mt-4'>
<Form.Label>Password </Form.Label>
<Form.Control   
type='password'
placeholder='Enter password'
value={password}
onChange={(e) => setPassword(e.target.value)}
></Form.Control>
</Form.Group>

<Button type='submit' variant='primary' className='mt-4'>
    Sign In

</Button>
        </Form>
        <Row className='py-3'>
<Col>
New Customer? <Link to={redirect? `/register?redirect=${redirect}`:`/register`}>Register
</Link> </Col>
        </Row>
        </Col>
        </Row>
    </Container>
    </div>
  )
}

export default LoginScreen