import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../Components/Message'
import Loader from './loader'
import { productAction } from '../productAction'

const ProductListScreen = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(productAction())
  },[dispatch])
  const productList=useSelector((state)=>state.productList)
  const{loading,error,products}=productList
  return (
    <>
    <Button className='my-3' >
            <i className='fas fa-plus'></i> Create Product
          </Button>
    <Row className='align-items-center'>
        <Col>
        <h1>Product Admin List</h1>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message text={error} variant='danger'></Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Product NAME</th>
              <th>Product INSTOCK</th>
              <th>Product CATEGORY</th>
              <th>Product PRICE</th>
              <th>EDIT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {products && products.map((product) => (
              <tr key={product._id}>
                <td>{product._id}</td>
                <td>{product.name}</td>
                
                <td>
                  {product.Stock>0 ? (
                    <i className='fas fa-check' style={{ color: 'green' }}></i>
                  ) : (
                    <i className='fas fa-times' style={{ color: 'red' }}></i>
                  )}
                </td>
                <td>{product.category}
                </td>
                <td>
${product.price}
                </td>
                <td>
                  <LinkContainer to={`/admin/user/${product._id}/edit`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-edit'></i>
                    </Button>
                  </LinkContainer>
                  <Button
                    variant='danger'
                    className='btn-sm'
                    // onClick={() => deleteHandler(user._id)
                    // }
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default ProductListScreen