import React, { useEffect } from 'react'
import Loader from './loader'
import { Col, Table,Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../Components/Message'
import { useDispatch, useSelector } from 'react-redux'
import { getUserOrderList } from '../Actions/orderActions'

const MyOrders = () => {
    const dispatch=useDispatch()
    const {OrderUserList,loadingOrderUserList,errorOrderList}= useSelector((state) => state.order)
useEffect(()=>{
    dispatch(getUserOrderList())
},[])
  return (
    <div><Col md={9}>
    <h2>My Orders</h2>
    {loadingOrderUserList ? (
      <Loader />
    ) : errorOrderList ? (
      <Message variant='danger' text={'Fetching Orders Failed or Unauthorised Request. Please Login'}></Message>
    ) : (
      <Table striped bordered hover responsive className='table-sm'>
        <thead>
          <tr>
            <th>ID</th>
            <th>DATE</th>
            <th>TOTAL</th>
            <th>PAID</th>
            <th>DELIVERED</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {OrderUserList&&OrderUserList.map((order) => (
            <tr key={order._id}>
              <td>{order._id}</td>
              <td>{order.createdAt.substring(0, 10)}</td>
              <td>{order.totalPrice}</td>
              <td>
                {order.isPaid ? (
                  order.paidAt.substring(0, 10)
                ) : (
                  <i className='fas fa-times' style={{ color: 'red' }}></i>
                )}
              </td>
              <td>
                {order.isDelivered ? (
                  order.deliveredAt.substring(0, 10)
                ) : (
                  <i className='fas fa-times' style={{ color: 'red' }}></i>
                )}
              </td>
              <td>
                <LinkContainer to={`/order/${order._id}`}>
                  <Button className='btn-sm' variant='light'>
                    Details
                  </Button>
                </LinkContainer>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </Col></div>
  )
}

export default MyOrders