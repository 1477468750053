import React,{useEffect,useState} from 'react'
import{Link,useSearchParams,useNavigate} from 'react-router-dom'
import {Form,Button,Row,Col,Container} from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { register, registerComplete} from '../Actions/userAction'
import FormContainer from '../Components/FormContainer'
import Message from '../Components/Message'
const   RegisterScreen = () => {
    let [name,setName]= useState('')
    let [email,setEmail]= useState('')
    let [password,setPassword]= useState('')
    let [confirmPassword,setConfirmPassword]= useState('')
    let[message,setMessage]=useState(null)
    const [searchParams,setSearchParams]=useSearchParams()
    const redirect=searchParams.get('search')? searchParams.get('search').split('=')[1]:'/'
    const userRegister=useSelector(state=>state.userLogin)
    const {loading,err,userReg}=userRegister
    const dispatch=useDispatch()
    const navigate = useNavigate();
    let passwordmatch=false;

    useEffect(()=>{
        if(userReg){
             document.getElementById('email').value=""
             document.getElementById('name').value=""
             document.getElementById('password').value=""
             document.getElementById('confirmpassword').value=""
             const timer = setTimeout(() => {
              navigate("/login")
              dispatch(registerComplete())
            }, 4000);
            return () => clearTimeout(timer);
        }
    },[redirect,userReg,passwordmatch])
    const submitHandler=(e)=>{
       
        //prevent default will prevent refresh
e.preventDefault()
if(password===confirmPassword){
  setMessage(null)
dispatch(register(name,email,password))}
else{
  setMessage("true")
}


    }
  return (
    <div>
      <Container>
        <Row className='justify-content-md-center'>
            <Col xs={12} md={6}> 
        <h1>Register</h1>
        {userReg &&<div> <Message text={"User Registration Successful"} variant='success'/>  <Message text={"Redirecting to login Page..."} variant='warning'/></div>}
        {message && <Message text={"Oops Passwords Does Not Match"} variant='danger'/ >}
        {err &&<Message text={err} variant='danger'/>}
        <Form onSubmit={submitHandler}>

        <Form.Group className='mt-4'>
<Form.Label>Name</Form.Label>
<Form.Control
type='text'
className="name"
id="name"
placeholder='Enter Your Full Name'
value={name}
onChange={(e) => setName(e.target.value)}
></Form.Control>
</Form.Group>


        <Form.Group className='mt-4'>
<Form.Label>Email Address</Form.Label>
<Form.Control
type='email'
placeholder='Enter email'
value={email}
className="email"
id='email'
onChange={(e) => setEmail(e.target.value)}
></Form.Control>
</Form.Group>

<Form.Group  className='mt-4'>
<Form.Label>Password </Form.Label>
<Form.Control   
type='password'
placeholder='Enter password'
className="password"
value={password}
required={true}
id='password'
onChange={(e) => setPassword(e.target.value)}
></Form.Control>
</Form.Group>

<Form.Group  className='mt-4'>
<Form.Label>Confirm Your Password </Form.Label>
<Form.Control   
type='password'
className="confirmpassword"
id="confirmpassword"
placeholder='Confirm password'
value={confirmPassword}
onChange={(e) => setConfirmPassword(e.target.value)}
></Form.Control>
</Form.Group>

<Button type='submit' variant='primary' className='mt-4'>
    Register

</Button>
        </Form>
        
        </Col>
       
        </Row>
       
    </Container>
    </div>
  )
}

export default RegisterScreen