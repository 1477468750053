import {createReducer} from "@reduxjs/toolkit"

const initialState={}

export const userListReducer=createReducer({initialState},{
  
USER_LIST_ADMIN_REQUEST:(state,action)=>{
  return {
    loading:true
  }
},
USER_LIST_ADMIN_SUCCESS:(state,action)=>{
  return {
    loading:false,
    users:action.payload
  }
},
USER_LIST_ADMIN_FAILURE:(state,action)=>{
  return {
    loading:false,
    error:action.payload
  }
}
})

export const userLoginReducer=createReducer({initialState},{
    USER_LOGIN_REQUEST: (state,action)=>{
       return { loading:true,
        
       }
    },
    USER_LOGIN_SUCCESS: (state,action)=>{
      return{  loading:false,
        userInfo:action.payload
      }
    },
    USER_LOGIN_FAILED: (state,action)=>{
        return {loading:false,
        error:action.payload
      }
    },
    USER_LOGOUT: (state,action)=>{
        return {}
    },

    USER_REGISTER_REQUEST: (state,action)=>{
      return { loading:true,
       
      }
   },
   USER_REGISTER_SUCCESS: (state,action)=>{
     return{  loading:false,
       userReg:true
     }
   },
   USER_REGISTER_FAILED: (state,action)=>{
       return {loading:false,
        userReg:false,
       err:action.payload
     }
   },
   USER_REGISTER_COMPLETE: (state,action)=>{
    return {loading:false,
     userReg:false,
    
  }
},

USER_PROFILE_REQUEST: (state,action)=>{
  return {loading:true,
   userInfo:false,
  
}
},
USER_PROFILE_SUCCESS: (state,action)=>{
  return {loading:false,
   userInfo:action.payload,
  
}
},
USER_PROFILE_FAILED: (state,action)=>{
  return {loading:true,
   userInfoError:action.payload,
  
}
}
})
export const userDetailsReducer=createReducer({initialState},{
  
  USER_DETAILS_ADMIN_REQUEST:(state,action)=>{
    return {
      loading:true
    }
  },
  USER_DETAILS_ADMIN_SUCCESS:(state,action)=>{
    return {
      loading:false,
      user:action.payload
    }
  },
  USER_DETAILS_ADMIN_FAILURE:(state,action)=>{
    return {
      loading:false,
      error:action.payload
    }
  }
  })

  export const userDetailsUpdateReducer=createReducer({initialState},{
  
    USER_DETAILS_UPDATE_ADMIN_REQUEST:(state,action)=>{
      return {
        loading:true
      }
    },
    USER_DETAILS_UPDATE_ADMIN_SUCCESS:(state,action)=>{
      return {
        loading:false,
        successUpdate:true
      }
    },
    USER_DETAILS_UPDATE_ADMIN_FAILURE:(state,action)=>{
      return {
        loading:false,
        error:action.payload
      }
    }
    })