import {createReducer} from "@reduxjs/toolkit"

const initialState={}

export const productReducer=createReducer({initialState},{
    product_request: (state,action)=>{
       return { loading:true,
        products:[]
       }
    },
    product_success: (state,action)=>{
      return{  loading:false,
        products:action.payload
      }
    },
    product_failed: (state,action)=>{
        return {loading:false,
        error:action.payload}
    }
})