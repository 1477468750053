import React,{useEffect,useState} from 'react'
import {Link,useParams} from 'react-router-dom'
import {Row,Col ,Image,ListGroup,Button, ListGroupItem} from 'react-bootstrap'
import Rating from '../Components/Rating'
import {productDetailAction} from '../productAction'
import {useDispatch,useSelector} from 'react-redux'
import Loader from './loader'
import { useNavigate } from "react-router-dom";

const ProductScreen = () => {
    
const {id}=useParams()
const dispatch=useDispatch()
const navigate = useNavigate();
 // #! Should be same as one in store(state.productList)
 const productDetailList=useSelector(state=>state.productDetailList)
 const{loading,product,error}=productDetailList
  let [count,setCount]=useState(1);
    useEffect(() => {
       dispatch(productDetailAction(id))

    }, [id,dispatch])
    
    
  return (
    <div>
        
        {loading?
        <Loader/>
        : <div><Link className='btn btn-light my-3' to='/'>
            Go Back
        </Link>
        <Row >
            <Col  md={6}>
                <Image style={{width: "100%"}}  src={product && product.images && product.images[0].url} alt={product &&product.name} ></Image>
            </Col>
            <Col md={3}>
                <ListGroup variant='flush'>
                    <ListGroup.Item>
                        <h3>{product &&product.name}</h3>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Rating value={product && product.ratings} text={product &&product.numOfReviews}/>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Row>
                            <Col>
                        Price :<strong>&#8377;{product && product.price}</strong>
                        </Col>
                        
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Description : {product && product.description}
                    </ListGroup.Item>
                    
                </ListGroup>
            </Col>
            <Col>
            <ListGroup>
            <ListGroup.Item>
                <Row>
                    <Col>Price:</Col>
                    <Col>&#8377;<strong>{product && product.price}</strong></Col>
                </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                <Row>
                    <Col>
                    Status:
                    </Col>
                    <Col>
                    {product && product.Stock> 0 ?'In Stock':'Out Of Stock'}
                    </Col>
                </Row>
                
                </ListGroup.Item>
                <ListGroupItem>
                <Row>
                    <Col>
                    Qty</Col>
                    <Col>
                    <div className="input-group" style={{width:"150px"}}>
                                  <span className="input-group-btn" >
                                      <button type="button" className="quantity-left-minus btn btn-sm btn-danger btn-number" style={{width:"20px"}}  data-field="" onClick={decrement}>
                                        <span className="glyphicon glyphicon-minus">-</span>
                                      </button>
                                  </span>
                                  <div  id="qty" style={{width:"45px",textAlign:"center",margin:"auto"}}>{count}</div>
                                  <span className="input-group-btn">
                                      <button type="button" className="btn btn-success btn-number btn-sm" style={{width:"22px"}} data-type="plus" data-field="" onClick={increment}>
                                          <span >+</span>
                                      </button>
                                  </span>
                                  </div>
                    </Col>
                </Row>
                </ListGroupItem>
                <ListGroup.Item>
                    <Row>
                    <Button className='btn-block' type='button' disabled={product && product.Stock===0} onClick={addCartHandler}>
                        ADD TO CART
                    </Button>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
            </Col>
        </Row></div> }
       </div>
  )

  function addCartHandler(){
   
    navigate(`/cart/${product._id}?qty=${count}`)

  }

  function increment() {
   if(count+1<=product.Stock)
   setCount(++count)
    
      
   }
 function decrement() {
    
    if(count-1!=0)
    setCount(--count)
     
 }
}

export default ProductScreen