import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {Link,useParams, useSearchParams,useNavigate} from 'react-router-dom'
import {Row,Col,ListGroup,Image,Form,Button,Card, ListGroupItem} from 'react-bootstrap'
import {addToCart, removeFromCart} from '../Actions/cartAction'
const CartScreen = () => {
  const {id}=useParams()
// if not added then diaptch wont get activated after redirection ...refresh would be needed.
  //const location=useLocation()
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [searchParams,setSearchParams]=useSearchParams()
  const qty=Number(searchParams.get('qty'))
  const cart=useSelector(state=>state.cart)

  const {cartItems}=cart
  useEffect(()=>{
    if(id){
      dispatch(addToCart(id,qty))
    }
  },[dispatch,id,qty])
  const removeFromCartHandler=(id)=>{
    dispatch(removeFromCart(id))
  }
  return (
   <Row>
    <Col md={8}>
    <Link className='btn btn-light my-3' to='/'>
            Go Back
        </Link>
      <h1>Shopping Cart</h1>
      {cartItems && cartItems.length ===0 ? (<h3>Cart is empty <Link to='/'>Go Back</Link></h3>):
      (<div> <ListGroup>
        {cartItems&&
        cartItems.map((item)=>
        (<ListGroupItem key={item.product}>
            <Row>
              <Col md={2}>
                <Image src={item && item.image && item.image[0].url} alt={item.name} fluid rounded/>
              </Col>
              <Col md={3}>
                <Link to={`/product/${item.product}`}>{item.name}</Link>
              </Col>
              <Col md={2}>Rs.{item.price}</Col>
            <Col md={2}>Qty : {item.qty}</Col>
            <Col md={2}>
            <Button type='button' variant='light' onClick={()=>removeFromCartHandler(item.product)} >
<i className='fas fa-trash'></i>
</Button></Col>
            </Row>
          </ListGroupItem>)
        )}
      </ListGroup> </div>)}
    </Col>
    <Col md={4}>
      <Card>
        <ListGroup>
          <ListGroupItem>
            <h2>Subtotal ({cartItems &&cartItems.reduce((acc,item)=>acc+item.qty,0)})Items </h2>
            Rs.{cartItems&&cartItems.reduce((acc,item)=> acc+item.qty*item.price,0).toFixed(2)}
          </ListGroupItem>
          <ListGroupItem>
            <Button type='button' disabled={cartItems&&cartItems.length===0} className='btn-block' onClick={checkoutHandler}> Proceed To Checkout

            </Button>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Col>
    <Col md={2}>
    </Col>
   </Row>
  )

  function checkoutHandler(){
    navigate(`/shipping`)
  }
}

export default CartScreen