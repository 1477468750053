import Header from "./Components/Header";
import Footer from "./Components/Footer";
import {BrowserRouter as Router,Route,Routes,HashRouter} from 'react-router-dom'
import "./index.css"
import {Container} from 'react-bootstrap'
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/Register";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/orderScreen";
import MyOrders from "./screens/MyOrders";
import UserListScreen from "./screens/userListAdminScreen";
import UserEditScreen from "./screens/userEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
function App() {
  return (
    <HashRouter basename="/">
    <div className="App">
      <Header/>
      <main className="py-3">
        <Container>
          <Routes>
      <Route path="/" element={<HomeScreen/>} />
      <Route path="/product/:id?" element={<ProductScreen/>} />
      <Route path='/login' element={<LoginScreen/>} />
      <Route path='/shipping' element={<ShippingScreen/>} />
      <Route path='/payment' element={<PaymentScreen/>} />
      <Route path='/placeorder' element={<PlaceOrderScreen/>} exact />
      <Route path='/register' element={<RegisterScreen/> }/>
      <Route path='/profile' element={<ProfileScreen/> }/>
      <Route path="/cart/:id?" element={<CartScreen/>} />
      <Route path="/order/:id?" element={<OrderScreen/>} />
      <Route path="/myOrders" element={<MyOrders/>} />
      <Route path="/admin/userlist" element={<UserListScreen/>} />
      <Route path="/admin/user/:id/edit" element={<UserEditScreen/>} />
      <Route path="/admin/productlist" element={<ProductListScreen/>}/>
      </Routes>
      </Container>
      </main>
      <Footer/>
    </div>
    </HashRouter>
  );
}

export default App;
