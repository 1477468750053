import {createReducer} from "@reduxjs/toolkit"

const initialState={}

export const productDetailReducer=createReducer({initialState},{
    product_detail_request: (state,action)=>{
       return { loading:true,
        product:[]
       }
    },
    product_detail_success: (state,action)=>{
      return{  loading:false,
        product:action.payload
      }
    },
    product_detail_failed: (state,action)=>{
        return {loading:false,
        error:action.payload}
    }
})