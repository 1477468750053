import axios from "axios"


export const login=(email,password)=>async(dispatch)=>{
    try {
        dispatch({
            type:'USER_LOGIN_REQUEST'
        })
        const config={
            headers:{
                'Content-type':'application/json'
            }
        }
        const {data}=await axios.post(`/api/user/login`,{email,password},config)
        dispatch({
            type:'USER_LOGIN_SUCCESS',
            payload:data
        })
        localStorage.setItem('userInfo',JSON.stringify(data))
    } catch (error) {
        dispatch({
            type:'USER_LOGIN_FAILED',
            payload:error.response.data.message
        })
    }
}

export const logout=()=>async(dispatch)=>{
    localStorage.removeItem('userInfo')
    await axios.post('/api/user/logout')
    dispatch({
        type:'USER_LOGOUT'
    })

}

export const listUsers=()=>async(dispatch)=>{
    
    try {
        dispatch({
            type:'USER_LIST_ADMIN_REQUEST'
        })
      
        const {data}=await axios.get(`/api/user/getAllUser`)
        dispatch({
            type:'USER_LIST_ADMIN_SUCCESS',
            payload:data
        })
        
    } catch (error) {
        dispatch({
            type:'USER_LIST_ADMIN_FAILED',
            payload:error.response.data.message
        })
    }
   

}
export const deleteUser=(id)=>async(dispatch)=>{
    
    try {
       
      
        const {data}=await axios.delete(`/api/user/deleteUser/${id}`)
        dispatch({
            type:'USER_DELETE_ADMIN_SUCCESS',
            payload:data
        })
        
    } catch (error) {
        dispatch({
            type:'USER_LIST_ADMIN_FAILED',
            payload:error.response.data.message
        })
    }
   

}

export const register=(name,email,password)=>async(dispatch)=>{
    try {
        dispatch({
            type:'USER_REGISTER_REQUEST'
        })
        const config={
            headers:{
                'Content-type':'application/json'
            }
        }
        const {data}=await axios.post(`/api/user/createUser`,{name,email,password},config)
        dispatch({
            type:'USER_REGISTER_SUCCESS',
            payload:data
        })
        
    } catch (error) {
        dispatch({
            type:'USER_REGISTER_FAILED',
            payload:error.response.data.message
        })
    }
}
export const registerComplete=()=>(dispatch)=>{
    dispatch({
        type:'USER_REGISTER_COMPLETE',
       
    })
}

export const getUser=()=>async(dispatch)=>{
    try {
        dispatch({
            type:'USER_PROFILE_REQUEST'
        })
        const config={
            headers:{
                'Content-type':'application/json'
            }
        }
        const {data}=await axios.get(`/api/user/getUser`,{},config)
        dispatch({
            type:'USER_PROFILE_SUCCESS',
            payload:data
        })
        
    } catch (error) {
        dispatch({
            type:'USER_PROFILE_FAILED',
            payload:error.response.data.message
        })
    }
}

export const getUserDetails=(id)=>async(dispatch)=>{
    try {
        dispatch({
            type:'USER_DETAILS_ADMIN_REQUEST'
        })
        const config={
            headers:{
                'Content-type':'application/json'
            }
        }
        const {data}=await axios.get(`/api/user/getUser/${id}`,{},config)
        dispatch({
            type:'USER_DETAILS_ADMIN_SUCCESS',
            payload:data
        })
        
    } catch (error) {
        dispatch({
            type:'USER_DETAILS_ADMIN_FAILED',
            payload:error.response.data.message
        })
    }
}

export const updateUser=({id,name,email,role})=>async(dispatch)=>{
    try {
        dispatch({
            type:'USER_DETAILS_UPDATE_ADMIN_REQUEST'
        })
        
        const {data}=await axios.put(`/api/user/updateUserAdmin/${id}`,{name,email,role})
        dispatch({
            type:'USER_DETAILS_UPDATE_ADMIN_SUCCESS',
            payload:data
        })
        
    } catch (error) {
        dispatch({
            type:'USER_DETAILS_UPDATE_ADMIN_FAILED',
            payload:error.response.data.message
        })
    }
}