import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../Components/Message'
import { getOrderDetails, payOrder } from '../Actions/orderActions'
import Loader from './loader'
import { PayPalButton } from "react-paypal-button-v2";
import axios from 'axios'



const OrderScreen = ({location} ) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const[sdkReady,setSdkReady]=useState(false)
  const {loading,orderDetails,error} = useSelector((state) =>state.order)
  
  const {loading1:loadingPay,error:errorPay,success} = useSelector((state) =>state.order)

  const {id}=useParams()
  useEffect(() => {
    const addPayPalScript=async()=>{
      const{data: clientId}=await axios.get('/api/config/paypal')
      const script=document.createElement('script')
      script.type='text/javascript'
      script.async=true
      script.src=`https://www.paypal.com/sdk/js?client-id=${clientId}`
      script.onload=()=>{
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }
    

    if(!orderDetails || success){
      dispatch({type:"ORDER_PAY_RESET"})
     
    dispatch(getOrderDetails(id))
    dispatch({type:"CART_RESET"})
  }
    else if(!orderDetails.isPaid){
      if(!window.paypal){
        addPayPalScript()
      }else{
        setSdkReady(true)
      }
    }
  }, [orderDetails,dispatch,success])
 

var [internalLoading,setInternalLoading]=useState(true)

 // Calculate prices
 let itemsPrice;
 if(!internalLoading){
const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2)
}

 itemsPrice = addDecimals(
  orderDetails.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
)
}
useEffect(() => {
    if (orderDetails && orderDetails.user && orderDetails.orderItems) {
      setInternalLoading(false)
    }else{
      setInternalLoading(true)
    }
  }, [orderDetails])

  const successPaymentHandler=(paymentResult)=>{
    console.log(paymentResult)
    setInternalLoading(true)
    dispatch(payOrder(orderDetails._id,paymentResult))
  }
  return ( 
    <>
{internalLoading && !error?<Loader></Loader>:error?<Message variant="danger" text="Order Doesnt exist with. If you think this is a Mistake Please contact Our Customer Support Executive with your OrderId At CUSTOMER_SUPPORT_24@KP$TORE.IN"/>: <div>
<Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Name: </strong> {orderDetails.user.name}
              </p>
              <p>
                <strong>Email: </strong>{' '}
                <a href={`mailto:${orderDetails.user.email}`}>{orderDetails.user.email}</a>
              </p>
              <p>
                <strong>Address:</strong>
                {orderDetails.shippingAddress.address}, {orderDetails.shippingAddress.city}{' '}
                {orderDetails.shippingAddress.postalCode},{' '}
                {orderDetails.shippingAddress.country}
              </p>
              {orderDetails.isDelivered ? (
                <Message text={`Delivered On ${orderDetails.deliveredAt}`} variant='success'>
                 
                </Message>
              ) : (
                <Message text="Not Delivered Yet 🚚" variant='danger'></Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {orderDetails.paymentMethod}
              </p>
              {orderDetails.isPaid ? (
                <Message text={'Paid on 💰 '+ orderDetails.paidAt } variant='success'></Message>
              ) : (
                <Message text="Not Paid 💰" variant='danger'></Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {orderDetails.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {orderDetails.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>${itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>${orderDetails.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${orderDetails.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>${orderDetails.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {!orderDetails.isPaid && (
                <ListGroup.Item>
                 
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={orderDetails.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </ListGroup.Item>
              )}
              {/* {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type='button'
                      className='btn btn-block'
                  //    onClick={deliverHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )} */}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>}
      
    </>
  )
}

export default OrderScreen