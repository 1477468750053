import {Card, CardImg} from 'react-bootstrap'
import React from 'react'
import Rating from './Rating'
import{Link,useSearchParams,} from 'react-router-dom'

const Product = ({product}) => {
  return (
    <div>
<Card className='rounded my-3 py-3'>
    <Link to={`/product/${product._id}`}>
        <CardImg src={product.images[0].url} variant='top'></CardImg>
    </Link>
    <Card.Body>
    <Link to={`/product/${product._id}`}>
        <Card.Title as='div'>
            <strong>{product.name}</strong>
        </Card.Title>
    </Link>
    <Card.Text as='div'>
        <Rating value={product.ratings} text={product.numOfReviews}></Rating>
    </Card.Text>
    <Card.Text as='h3'>
        &#8377;{product.price}
    </Card.Text>
    </Card.Body>
</Card>

    </div>
  )
}

export default Product