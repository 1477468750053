import Alert from 'react-bootstrap/Alert';

import React from 'react'

const Message = ({text,variant}) => {
    return (
        <>
          {
            <Alert  variant={variant}>
              {text}
            </Alert>
          }
        </>
      );
}

export default Message
