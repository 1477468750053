import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../Components/Message'
import Loader from './loader'
import { getUserDetails, updateUser } from '../Actions/userAction'


const UserEditScreen = ({ match, history }) => {
  
  const {id:userId}=useParams()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)

  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

useEffect(()=>{
dispatch(getUserDetails(userId))

},[dispatch])



  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateUser({id: userId, name, email, role}))
  }
  const adminSubmitHandler = (e) => {
    
    if(e){
      console.log("admin")
      setRole('Admin')
    }else{
      console.log('user')
      setRole('User')
    }
  }

  return (
    <>
    <Container>
      <Row className='justify-content-md-center'>
        <Col xs={12} md={6}>
      <Link to='/admin/userlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      
        <h1>Edit User</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder={user && user.name}
                defaultValue={user && user.name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
             
            </Form.Group>
            <br></br>
            <Form.Group controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                defaultValue={user && user.email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br></br>
            <Form.Group controlId='isadmin'>
              <Form.Check
                type='checkbox'
                label='Is Admin'
                defaultChecked={user&&user.role==='Admin'}
                onChange={(e) => adminSubmitHandler(e.target.checked)}
              ></Form.Check>
            </Form.Group>
            <br></br>
            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
       </Col>
      </Row>
    </Container>
    </>
  )
}

export default UserEditScreen