import React from 'react'
import { Navbar,Nav,Container, NavDropdown } from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { logout } from '../Actions/userAction'
import{useNavigate} from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate();
  const userLogin=useSelector(state=>state.userLogin)
  const dispatch=useDispatch()
  const {userInfo}=userLogin

  const logOutHandle=()=>{
    
dispatch(logout())
setTimeout(()=>{
  navigate('/login')
},1000)

  }
  return (
    <header>
        <Navbar bg="dark" variant='dark' expand="lg" collapseOnSelect>
      <Container>
    <LinkContainer to='/'>
        <Navbar.Brand >KP $tore</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
          <LinkContainer to='/cart'>
            <Nav.Link >
               <i className='fas fa-shopping-cart'></i> CART</Nav.Link>
               </LinkContainer>
{userInfo ? (<NavDropdown title={userInfo.User || userInfo.name} id='username'>
<LinkContainer to='/profile'>
<NavDropdown.Item>Profile</NavDropdown.Item>
</LinkContainer>
<LinkContainer to='/myOrders'>
<NavDropdown.Item>My Orders</NavDropdown.Item>
</LinkContainer>
<NavDropdown.Item onClick={logOutHandle}>Logout</NavDropdown.Item>
</NavDropdown>):<LinkContainer to='/login'>
            <Nav.Link ><i className='fas fa-user'></i>SIGN IN</Nav.Link>
            </LinkContainer>}
            {userInfo && userInfo.role==='Admin' ? (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              ): <div/>}
               
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </header>
  )
}

export default Header